import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-inspection-modal',
  templateUrl: './inspection-modal.component.html',
  styleUrls: ['./inspection-modal.component.scss']
})
export class InspectionModalComponent implements OnInit {
  @Input() data;
  constructor(private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  accept(){
    this.modalService.dismissAll();
    this.router.navigateByUrl('/dashboard');
  }

 

}
