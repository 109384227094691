import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuotesService } from '@services/quotes.service';

@Component({
  selector: 'app-insured-value',
  templateUrl: './insured-value.component.html',
  styleUrls: ['./insured-value.component.scss']
})
export class InsuredValueComponent implements OnInit {
  @Input() data;
  userValue: number = 0;
  constructor(private quoteService: QuotesService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.userValue = this.data.minValue;
  }

  setValue(e) {
    this.userValue = e;
  }

  maxValue() {
    this.userValue = this.data.maxValue
    this.modalService.dismissAll();
  }

  quote() {
    this.quoteService.setInsuredAmount(this.data.quoteId, this.userValue).subscribe(response => {
      this.modalService.dismissAll();
      this.router.navigateByUrl(`/cotizaciones/resultados?quoteId=${response.data.id}`);
    })
  }

}
