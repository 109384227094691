import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class AseguradosService {

	private headers: any;

	constructor(
		private http: HttpClient,
		private AuthService: AuthService
		) {
		this.headers = new HttpHeaders();
		this.headers = this.headers.set('Content-Type', 'application/json')
	}

	public get(offset, query:string): Observable<any> {
		return this.http.post(`${environment.apiUrl}users/clients?offset=${offset}&limit=10&query=${query}`,  { headers: this.headers });
	}
	public getById(id): Observable<any> {
		return this.http.post(`${environment.apiUrl}users/clients/`+id,  { headers: this.headers });
	}
	public update(asegurado, id): Observable<any> {
		return this.http.put(`${environment.apiUrl}users/`+id, {
			phone: asegurado.phone,
			postal_code: asegurado.cp,
			first_name: asegurado.first_name,
			last_name: asegurado.last_name,
			address: asegurado.address,
			birth_date: asegurado.birth_date,
			dni: asegurado.dni,
		}, { headers: this.headers });
	}
	public getByEmail(query:string): Observable<any> {
		return this.http.post(`${environment.apiUrl}users/clients?query=${query}`, { headers: this.headers });
	}
	public getCardsById(id): Observable<any> {
		return this.http.get(`${environment.apiUrl}users/clients/`+id+'/credit_cards',  { headers: this.headers });
	}
}
