import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from '@services/auth.service';
import { User } from '@models/user.model';
import { Breadcrumb } from '@helpers/breadcrumbs';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public focus;
    public listTitles: any[];
    public currentUser: User;
    public breadcrumbs: Breadcrumb[];

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private AuthService: AuthService

    ) {

    }

    ngOnInit() {
        this.currentUser = this.AuthService.user;
        console.log(this.currentUser);
    }

    logout() {
        this.AuthService.logout();
        this.router.navigate(['auth/login']);
    }


}
