import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { User } from '@models/user.model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private headers: any;
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router) {
        this.headers = new HttpHeaders();
        this.headers = this.headers.set('Content-Type', 'application/json');

    }

    login(email: string, password: string, token: string): Observable<any> {
        return this.http
            .post<any>(`${environment.apiUrl}auth/login`, { email: email, password: password, token: token }, { headers: this.headers })
            .pipe(map((res: any) => {
                localStorage.setItem('token', res.token);
                localStorage.setItem('user', JSON.stringify(res.user));
            }));
    }
    getToken() {
        return localStorage.getItem('token');
    }

    get user() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user;
    }

    get role() {
        const user = JSON.parse(localStorage.getItem('user'));
        return user.role;
    }

    get isLoggedIn(): boolean {
        let authToken = localStorage.getItem('token');
        return authToken !== null ? true : false;
    }

    logout() {
        let removeToken = localStorage.removeItem('token');
        let removeUser = localStorage.removeItem('user');
    }



    public forgot(email: string) {
        return this.http.post(`${environment.apiUrl}auth/forgot`, { email: email }, { headers: this.headers });
    }

    public reset( password: string, passwordConfirm: string, code: string) {
        return this.http.post(`${environment.apiUrl}auth/recover`, { password: password, password_confirm: passwordConfirm, code: code }, { headers: this.headers });
    }

    validateCode(code: string): Observable<any> {
        return this.http.post(`${environment.apiUrl}users/validate-code`, { code }, { headers: this.headers });
    }

}
