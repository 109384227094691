import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '@services/event.service';
import { PoliciesService } from '@services/policies.service';

@Component({
  selector: 'app-vehicle-modal',
  templateUrl: './vehicle-modal.component.html',
  styleUrls: ['./vehicle-modal.component.scss']
})
export class VehicleModalComponent implements OnInit {

  @Input() policyId: string;
  vehicle: any = {
    plate: '',
    engine: '',
    chasis: ''
  };

  constructor(
    private modalService: NgbModal,
    private policiesService: PoliciesService,
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    this.getPolicy();
  }

   close(){
    this.modalService.dismissAll();
  }

  getPolicy(){
    this.policiesService.getById(this.policyId).subscribe({
      next: response => {
       this.vehicle.plate = response.data.plate;
       this.vehicle.chasis = response.data.chasis;
       this.vehicle.engine = response.data.engine;
      },

      error: error => {
        console.log(error);
      }
    });
    
  }

  onSubmit() {
   
    this.policiesService.updateVehicle(this.vehicle, this.policyId).subscribe({
      next: response => {
        this.eventService.emit('update');
        this.modalService.dismissAll();
      },

      error: error => {
        console.log(error);
      }
    });
  }

}
