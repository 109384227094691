import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EventService } from '@services/event.service';
import { PoliciesService } from '@services/policies.service';

@Component({
  selector: 'app-cancel-policy-modal',
  templateUrl: './cancel-policy-modal.component.html',
  styleUrls: ['./cancel-policy-modal.component.scss']
})
export class CancelPolicyModalComponent implements OnInit {
  closeResult = '';
	@Input() text;
  @Input() policyId;
	constructor(
    private modalService: NgbModal,
    private eventService: EventService,
    private policiesService: PoliciesService,
  ) {}

	ngOnInit() {
    console.log(this.text);
	}
  close(){
    this.modalService.dismissAll();
  }

	cancelPolicy(){
    this.policiesService.cancel(this.policyId).subscribe({
      next: response=>{
        this.close();
        this.eventService.emit('update');
      },
      error: error =>{
        console.log(error);
      }
    });
	}
}
