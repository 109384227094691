import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

	closeResult = '';
	@Input() data;

	constructor() {}

	ngOnInit() {
	}


	accept(){
		
	}

}
