import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { User } from "@models/user.model";
import { AuthService } from "@services/auth.service";
import * as eva from 'eva-icons';
declare interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	class: string;
}
export const ROUTES_PRODUCTOR: RouteInfo[] = [
	{
		path: "/dashboard",
		title: "Inicio",
		icon: "eva eva-home-outline",
		class: ""
	},
	{
		path: "/cotizaciones",
		title: "Cotizaciones",
		icon: "eva-grid-outline",
		class: ""
	},
	{
		path: "/asegurados",
		title: "Asegurados",
		icon: "eva-person-outline",
		class: ""
	},
	{
		path: "/polizas",
		title: "Pólizas",
		icon: "eva-file-outline",
		class: ""
	}

];

export const ROUTES_INSPECTOR: RouteInfo[] = [
	
	{
		path: "/inspecciones",
		title: "Inspecciones",
		icon: "eva-image-outline",
		class: ""
	}

];

export const ROUTES_SUPERADMIN: RouteInfo[] = [
	
	{
		path: "/dashboard",
		title: "Inicio",
		icon: "eva eva-home-outline",
		class: ""
	},
	{
		path: "/cotizaciones",
		title: "Cotizaciones",
		icon: "eva-grid-outline",
		class: ""
	},
	{
		path: "/asegurados",
		title: "Asegurados",
		icon: "eva-person-outline",
		class: ""
	},
	{
		path: "/polizas",
		title: "Pólizas",
		icon: "eva-file-outline",
		class: ""
	},
	{
		path: "/agentes",
		title: "Agentes",
		icon: "eva-briefcase-outline",
		class: ""
	},
	{
		path: "/inspecciones",
		title: "Inspecciones",
		icon: "eva-image-outline",
		class: ""
	},
	{
		path: "/administracion/",
		title: "Administración",
		icon: "eva-settings-2-outline",
		class: ""
	}


];

export const ROUTES_ADMIN: RouteInfo[] = [
	
	{
		path: "/dashboard",
		title: "Inicio",
		icon: "eva eva-home-outline",
		class: ""
	},
	{
		path: "/cotizaciones",
		title: "Cotizaciones",
		icon: "eva-grid-outline",
		class: ""
	},
	{
		path: "/asegurados",
		title: "Asegurados",
		icon: "eva-person-outline",
		class: ""
	},
	{
		path: "/polizas",
		title: "Pólizas",
		icon: "eva-file-outline",
		class: ""
	},
	{
		path: "/agentes",
		title: "Agentes",
		icon: "eva-briefcase-outline",
		class: ""
	},
	{
		path: "/inspecciones",
		title: "Inspecciones",
		icon: "eva-image-outline",
		class: ""
	}


];

@Component({
	selector: "app-sidebar",
	templateUrl: "./sidebar.component.html",
	styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
	public menuItems: any[];
	public isCollapsed = false;
	public user: User;
	constructor(private router: Router, private authService: AuthService) { }

	ngOnInit() {
		this.user = this.authService.user;
		if (this.user.role == 'PRODUCTOR') {
			this.menuItems = ROUTES_PRODUCTOR.filter(menuItem => menuItem);
		}
		if (this.user.role == 'SUPERADMIN') {
			this.menuItems = ROUTES_SUPERADMIN.filter(menuItem => menuItem);
		}
		
		if (this.user.role == 'ADMIN') {
			this.menuItems = ROUTES_ADMIN.filter(menuItem => menuItem);
		}

		if (this.user.role == 'INSPECTOR') {
			this.menuItems = ROUTES_INSPECTOR.filter(menuItem => menuItem);
		}


		this.router.events.subscribe(event => {
			this.isCollapsed = false;
		});
	}

	logout() {
		this.authService.logout();
		this.router.navigate(['auth/login']);
	}
}
