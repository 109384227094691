import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-photos-modal',
  templateUrl: './photos-modal.component.html',
  styleUrls: ['./photos-modal.component.scss']
})
export class PhotosModalComponent implements OnInit {
  @Input() data;
  constructor(private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  accept(){
    this.modalService.dismissAll();
    this.router.navigateByUrl('/cotizaciones');
  }

  close() {
    
    this.modalService.dismissAll();
    this.router.navigateByUrl('/cotizaciones');
  }

}
