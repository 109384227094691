import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-modal-delete',
	templateUrl: './modal-delete.component.html',
	styleUrls: ['./modal-delete.component.scss']
})
export class ModalDeleteComponent implements OnInit {
	closeResult = '';
	@Input() data;

  	constructor() {}

	ngOnInit() {
	}


	accept(){
		
	}
}
